<template>
  <div>
    <CRow>
      <CCol sm="12" md="12">
        <CCard>
          <CCardHeader>
            <h4>Edit Transaction Type-Rule Mapping</h4>
          </CCardHeader>
          <CCardBody>

            <CForm>
              <CInput label="Transaction Type Code" v-model="transactionTypeCode" readonly="readonly"
                placeholder="Enter Transaction Type Code" horizontal />

              <b-row>
                <b-col sm="3">
                  <label for="input-small">Rule Code</label>
                </b-col>
                <b-col sm="9">
                  <b-form-select v-model="ruleCode" @change="onChangeRuleCode()" disabled="disabled"
                    :options="optRuleCode">
                  </b-form-select>
                </b-col>
              </b-row><br />

              <CInput label="Rule Description" v-model="ruleDesc" readonly="readonly"
                horizontal />

              <span v-if="seenSameSenderGapSeconds">
                <b-row>
                  <b-col sm="3">
                    <label for="input-small">Transaction Velocity Break Interval by a User</label>
                    <!-- Same Sender Gap Seconds -->
                  </b-col>
                  <b-col sm="2">Days
                    <b-form-input type="number"
                      min="0"
                      max="366"
                      maxlength = "3"
                      onkeyup="if(this.value<0){this.value= this.value * -1}"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      v-model="ssgsDay"></b-form-input>
                      <label style="color:grey; font-size : 13px;">* Max. 366</label>
                  </b-col>
                  <b-col sm="2"> Hours
                    <b-form-input type="number"
                      min="0"
                      max="24"
                      maxlength = "2"
                      onkeyup="if(this.value<0){this.value= this.value * -1}"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      v-model="ssgsHour"></b-form-input>
                      <label style="color:grey; font-size : 13px;">* Max. 24</label>
                  </b-col>
                  <b-col sm="2"> Minutes
                    <b-form-input type="number"
                        min="0"
                        max="1440"
                        maxlength = "4"
                        onkeyup="if(this.value<0){this.value= this.value * -1}"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        v-model="ssgsMin"></b-form-input>
                        <label style="color:grey; font-size : 13px;">* Max. 1440</label>
                  </b-col>
                  <b-col sm="2"> Seconds
                    <b-form-input type="number"
                      min="0"
                      max="86400"
                      maxlength = "5"
                      onkeyup="if(this.value<0){this.value= this.value * -1}"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      v-model="ssgsScd"></b-form-input>
                      <label style="color:grey; font-size : 13px;">* Max. 86400</label>
                  </b-col>
                </b-row>
              </span>
              <br v-if="seenSameSenderGapSeconds" />

              <span v-if="seenSameSenderReceiverGapSeconds">
                <b-row>
                  <b-col sm="3">
                    <label for="input-small">Beneficiary Velocity Break Interval by a User</label>
                    <!-- Same Sender Receiver Gap Seconds -->
                  </b-col>
                  <b-col sm="2">Days
                    <b-form-input type="number"
                      min="0"
                      max="366"
                      maxlength = "3"
                      onkeyup="if(this.value<0){this.value= this.value * -1}"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      v-model="ssrgsDay"></b-form-input>
                      <label style="color:grey; font-size : 13px;">* Max. 366</label>
                  </b-col>
                  <b-col sm="2">Hours
                    <b-form-input type="number"
                      min="0"
                      max="24"
                      maxlength = "2"
                      onkeyup="if(this.value<0){this.value= this.value * -1}"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      v-model="ssrgsHour"></b-form-input>
                      <label style="color:grey; font-size : 13px;">* Max. 24</label>
                  </b-col>
                  <b-col sm="2">Minutes
                    <b-form-input type="number"
                      min="0"
                      max="1440"
                      maxlength = "4"
                      onkeyup="if(this.value<0){this.value= this.value * -1}"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      v-model="ssrgsMin"></b-form-input>
                      <label style="color:grey; font-size : 13px;">* Max. 1440</label>
                  </b-col>
                  <b-col sm="2">Seconds
                    <b-form-input type="number"
                      min="0"
                      max="86400"
                      maxlength = "5"
                      onkeyup="if(this.value<0){this.value= this.value * -1}"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      v-model="ssrgsScd"></b-form-input>
                      <label style="color:grey; font-size : 13px;">* Max. 86400</label>
                  </b-col>
                </b-row><br/>
              </span>

              <span v-if="seenSingleTransactionAmount">
                <b-row>
                  <b-col sm="3">
                    <label for="input-small">Max. Single Transaction Amount Per User</label>
                    <!-- Single Transaction Amount -->
                  </b-col>
                  <b-col sm="9">
                    <b-form-input type="number"
                    @change="strsAChange"
                    min=0
                    max="2147483647"
                    maxlength = "10"
                    onkeyup="if(this.value<0){this.value= this.value * -1}"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    step="any"
                    v-model="singleTransactionAmount"></b-form-input>
                  </b-col>
                </b-row><br />
              </span>

              <span v-if="seenPrevDayTotalExceedPercentage">
                <b-row>
                  <b-col sm="3">
                    <label for="input-small">Current vs Previous Day Value Excess Permitted (in %)</label>
                    <!-- Prev Day Total Exceed Percentage -->
                  </b-col>
                  <b-col sm="9">
                    <b-form-input type="number" maxlength="3" min=0
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      v-model="prevDayTotalExceedPercentage"></b-form-input>
                  </b-col>
                </b-row><br />
              </span>

              <span v-if="seenTotalTransSafetyValue">
                <b-row>
                  <b-col sm="3">
                    <label for="input-small">Current Day Max Safety Value</label>
                    <!-- Total Trans Safety Value -->
                  </b-col>
                  <b-col sm="9">
                    <b-form-input type="number"
                        @change="ttsChange"
                        min=0
                        max="2147483647"
                        maxlength = "10"
                        onkeyup="if(this.value<0){this.value= this.value * -1}"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        step="any"
                        v-model="totalTransSafetyValue"></b-form-input>
                  </b-col>
                </b-row><br/>
              </span>

              <span v-if="seenGlobalTransSafetyValue">
                <b-row>
                  <b-col sm="3">
                    <label for="input-small">Global Trans Safety Value</label>
                  </b-col>
                  <b-col sm="9">
                    <b-form-input type="number"
                        @change="gtsChange"
                        min=0
                        max="2147483647"
                        maxlength = "10"
                        onkeyup="if(this.value<0){this.value= this.value * -1}"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        step="any"
                        v-model="globalTransSafetyValue"></b-form-input>
                  </b-col>
                </b-row><br/>
              </span>
              <span v-if="seenRule0006">
                    <b-row>
                      <b-col sm="3">
                        <label for="input-small">Blocked Account Number List</label>
                      </b-col>
                      <b-col sm="9">
                        <multiselect :taggable="true" @tag="addTag" v-model="blockedAccountNumberList" :options="optBlockedAccount" :multiple="true" :showNoOptions="false" placeholder="Input Blocked Account Number List" label="name" track-by="code" :regex="/^[A-Za-z0-9*]/">
                              <template slot="selection2" slot-scope="{ values, search, isOpen }"><pre class="language-json"><code>{{ value  }}</code></pre></template>
                        </multiselect>
                        <label style="color:grey; font-size : 13px;"> press <strong>ENTER</strong> to input new tag | to input pattern, user can put the string between *...*, e.g : *1234*</label>
                      </b-col>
                    </b-row><br/>
                    <b-row>
                      <b-col sm="3">
                        <label for="input-small">Blocked Receiver Name List</label>
                      </b-col>
                      <b-col sm="9">
                        <multiselect :taggable="true" @tag="addTagReceiver" v-model="blockedReceiverNameList" :options="optBlockedReceiverName" :multiple="true" :showNoOptions="false" placeholder="Input Blocked Receiver Name List" label="name" track-by="code">
                              <template slot="selection2" slot-scope="{ values, search, isOpen }"><pre class="language-json"><code>{{ value  }}</code></pre></template>
                        </multiselect>
                        <label style="color:grey; font-size : 13px;"> press <strong>ENTER</strong> to input new tag | to input pattern, user can put the string between *...*, e.g : *1234*</label>
                      </b-col>
                    </b-row><br/>
                    <b-row>
                      <b-col sm="3">
                        <label for="input-small">Blocked Bank List</label>
                      </b-col>
                      <b-col sm="9">
                        <multiselect :taggable="true" @tag="addTagBank" v-model="blockedBankList" :options="optBlockedBank" :multiple="true" :showNoOptions="false" placeholder="Input Blocked Bank List" label="name" track-by="code">
                              <template slot="selection2" slot-scope="{ values, search, isOpen }"><pre class="language-json"><code>{{ value  }}</code></pre></template>
                        </multiselect>
                        <label style="color:grey; font-size : 13px;"> press <strong>ENTER</strong> to input new tag</label>
                      </b-col>
                    </b-row><br/>
                    <b-row>
                      <b-col sm="3">
                        <label for="input-small">Blocked NRIC List</label>
                      </b-col>
                      <b-col sm="9">
                        <multiselect :taggable="true" @tag="addTagNRIC" v-model="blockedNricList" :options="optBlockedNRIC" :multiple="true" :showNoOptions="false" placeholder="Input Blocked NRIC List" label="name" track-by="code">
                              <template slot="selection2" slot-scope="{ values, search, isOpen }"><pre class="language-json"><code>{{ value  }}</code></pre></template>
                        </multiselect>
                        <label style="color:grey; font-size : 13px;"> press <strong>ENTER</strong> to input new tag | to input pattern, user can put the string between *...*, e.g : *1234*</label>
                      </b-col>
                    </b-row><br/>
                    <b-row>
                      <b-col sm="3">
                        <label for="input-small">Blocked UEN List</label>
                      </b-col>
                      <b-col sm="9">
                        <multiselect :taggable="true" @tag="addTagUEN" v-model="blockedUenList" :options="optBlockedUEN" :multiple="true" :showNoOptions="false" placeholder="Input Blocked UEN List" label="name" track-by="code">
                              <template slot="selection2" slot-scope="{ values, search, isOpen }"><pre class="language-json"><code>{{ value  }}</code></pre></template>
                        </multiselect>
                        <label style="color:grey; font-size : 13px;"> press <strong>ENTER</strong> to input new tag | to input pattern, user can put the string between *...*, e.g : *1234*</label>
                      </b-col>
                    </b-row><br/>
                    <b-row>
                      <b-col sm="3">
                        <label for="input-small">Blocked E-mail List</label>
                      </b-col>
                      <b-col sm="9">
                        <multiselect :taggable="true" @tag="addTagEmail" v-model="blockedEmailList" :options="optBlockedEmail" :multiple="true" :showNoOptions="false" placeholder="Input Blocked E-mail List" label="name" track-by="code">
                              <template slot="selection2" slot-scope="{ values, search, isOpen }"><pre class="language-json"><code>{{ value  }}</code></pre></template>
                        </multiselect>
                        <label style="color:grey; font-size : 13px;"> press <strong>ENTER</strong> to input new tag | to input pattern, user can put the string between *...*, e.g : *1234*</label>
                      </b-col>
                    </b-row><br/>
                    <b-row>
                      <b-col sm="3">
                        <label for="input-small">Blocked Mobile List</label>
                      </b-col>
                      <b-col sm="9">
                        <multiselect :taggable="true" @tag="addTagMobile" v-model="blockedMobileList" :options="optBlockedMobile" :multiple="true" :showNoOptions="false" placeholder="Input Blocked Mobile List" label="name" track-by="code">
                              <template slot="selection2" slot-scope="{ values, search, isOpen }"><pre class="language-json"><code>{{ value  }}</code></pre></template>
                        </multiselect>
                        <label style="color:grey; font-size : 13px;"> press <strong>ENTER</strong> to input new tag | to input pattern, user can put the string between *...*, e.g : *1234*</label>
                      </b-col>
                    </b-row><br/>
              </span>

              <b-row>
                <b-col sm="3">
                  <label for="input-small">Status</label>
                </b-col>
                <b-col sm="9">
                  <b-form-select v-model="status" :options="optStatus">
                  </b-form-select>
                </b-col>
              </b-row>
              <br/>
              <CRow>
                <CCol col="12" class="text-left">
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol>
              </CRow>
              <b-row>
                <b-col sm="3"></b-col>
                <b-col sm="9">
                  <b-button-group>
                        <b-button v-on:click="ActionCancel()">
                          << Back to List
                        </b-button>
                        <b-button v-on:click="ActionUpdate()" variant="primary">
                          Update Type-Rule Mapping
                        </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import FraudService from '@/api/FraudService.js';
import VueTimepicker from 'vue2-timepicker';
import Multiselect from "vue-multiselect";

export default {
  name: 'edittyperulesmap',
  components: {
    Multiselect,
    VueTimepicker
  },
  data() {
    return {
      spinner: false,
      seenSameSenderGapSeconds: false,
      seenSameSenderReceiverGapSeconds: false,
      seenSingleTransactionAmount: false,
      seenPrevDayTotalExceedPercentage: false,
      seenTotalTransSafetyValue: false,
      seenGlobalTransSafetyValue: false,
      seenRule0006: false,
      id: this.$route.params.id,
      transactionTypeCode: null,
      ruleCode: null,
      ruleDesc:'',
      blockedAccountNumberList:[],
      blockedBankList:[],
      blockedEmailList:[],
      blockedMobileList:[],
      blockedNricList:[],
      blockedReceiverNameList:[],
      blockedUenList:[],
      rules:[],
      optRuleCode: [],
      optBlockedAccount:[],
      optBlockedBank:[],
      optBlockedEmail:[],
      optBlockedMobile:[],
      optBlockedReceiverName:[],
      optBlockedNRIC:[],
      optBlockedUEN:[],
      viewSsgsDay: 0,
      viewSsrgsDay: 0,
      ssgsDay: 0,
      ssgsHour: 0,
      ssgsMin: 0,
      ssgsScd: 0,
      ssgsTime: '00:00:00',
      ssrgsDay: 0,
      ssrgsHour: 0,
      ssrgsMin: 0,
      ssrgsScd: 0,
      ssrgsTime: '00:00:00',
      cbSsgsDay: false,
      cbSsrgsDay: false,
      seenSsgsTime: false,
      seenSsrgsTime: false,
      sameSenderGapSeconds: 0,
      sameSenderReceiverGapSeconds: 0,
      singleTransactionAmount: 0,
      prevDayTotalExceedPercentage: 0,
      totalTransSafetyValue: 0,
      globalTransSafetyValue:0,
      status: 'ACTIVE',
      optStatus: ['ACTIVE', 'INACTIVE'],
      seen: false,
      msg: '',
      color: ''
    };
  },
  created() {
    this.getAllRule();
    this.getTransactionRuleById(this.id);
  },
  methods: {

    countdown(s) {
        const d = Math.floor(s / (3600 * 24));
        s  -= d * 3600 * 24;

        const h = Math.floor(s / 3600);
        s  -= h * 3600;

        const m = Math.floor(s / 60);
        s  -= m * 60;

        const tmp = [];
        tmp.push(d);
        tmp.push(h);
        tmp.push(m);
        tmp.push(s);
        //format 'd', 'h', 'm', 's'
        //return tmp.join(' ');
        return tmp;
    },

    onModifySsgsDay() {
      if (this.cbSsgsDay === "true") {
        this.seenSsgsTime = true;
      } else {
        this.seenSsgsTime = false;
        this.ssgsDay = 0;
        this.ssgsHour = 0;
        this.ssgsMin = 0;
        this.ssgsScd = 0;
        this.sameSenderGapSeconds = this.viewSsgsDay;
      }
    },

    onModifySsrgsDay() {
      if (this.cbSsrgsDay === "true") {
        this.seenSsrgsTime = true;
      } else {
        this.seenSsrgsTime = false;
        this.ssrgsDay = 0;
        this.ssrgsHour = 0;
        this.ssrgsMin = 0;
        this.ssrgsScd = 0;
        this.sameSenderReceiverGapSeconds = this.viewSsrgsDay;
      }
    },

    timeToSeconds(days, hours, minutes, seconds) {
      return (days * 86400) + (hours * 3600) + (minutes * 60) + parseInt(seconds);
    },

    dayToSeconds(days) {
      return days * 24 * 60 * 60;
    },

    strsAChange() {
      var val = parseFloat(this.singleTransactionAmount);
      this.singleTransactionAmount = val.toFixed(2);
    },

    ttsChange() {
      var val = parseFloat(this.totalTransSafetyValue);
      this.totalTransSafetyValue = val.toFixed(2);
    },

    gtsChange(){
        var val = parseFloat(this.globalTransSafetyValue);
        this.globalTransSafetyValue = val.toFixed(2);
    },

    ssgsChange() {
      var term = this.ssgsTime; var temp = this.ssgsTime;

      if (term.match(/HH|mm|ss/g)) {
        var temp = term.replace("HH", "00").replace("mm", "00").replace("ss", "00");
      }

      this.ssgsTime = temp;

    },

    ssrgsChange() {

      var term = this.ssrgsTime; var temp = this.ssrgsTime;

      if (term.match(/HH|mm|ss/g)) {
        var temp = term.replace("HH", "00").replace("mm", "00").replace("ss", "00");
      }

      this.ssrgsTime = temp;

    },

    addTag (newTag) {
      // if (newTag.)
      let regex = /^[a-zA-Z0-9-_,.@#* \b]+$/
      if (!regex.test(newTag)) {
        alert("some of the tag has disallowed character")
      } else {
        const tag = {
          name: newTag,
          code: newTag
        }
        this.blockedAccountNumberList.push(tag)
      }
    },

    addTagBank (newTag) {
      let regex = /^[a-zA-Z0-9-_,.()# \b]+$/
      if (!regex.test(newTag)) {
        alert("some of the tag has disallowed character")
      }else {
        const tag = {
          name: newTag,
          code: newTag
        }
        this.blockedBankList.push(tag)
      }
    },

    addTagUEN (newTag) {
      let regex = /^[a-zA-Z0-9-_,.#* \b]+$/
      if (!regex.test(newTag)) {
        alert("some of the tag has disallowed character")
      } else {
        const tag = {
          name: newTag,
          code: newTag
        }
        this.blockedUenList.push(tag)
      }
    },

    addTagNRIC (newTag) {
      let regex = /^[a-zA-Z0-9-_,.#* \b]+$/
      if (!regex.test(newTag)) {
        alert("some of the tag has disallowed character")
      } else {
        const tag = {
          name: newTag,
          code: newTag
        }
        this.blockedNricList.push(tag)
      }
    },

    addTagEmail (newTag) {
      let regex = /^[a-zA-Z0-9-_,.#*@ \b]+$/
      if (!regex.test(newTag)) {
        alert("some of the tag has disallowed character")
      } else {
        const tag = {
          name: newTag,
          code: newTag
        }
        this.blockedEmailList.push(tag)
      }
    },

    addTagMobile (newTag) {
      let regex = /^[a-zA-Z0-9-_,.#*+ \b]+$/
      if (!regex.test(newTag)) {
        alert("some of the tag has disallowed character")
      } else {
        const tag = {
          name: newTag,
          code: newTag
        }
        this.blockedMobileList.push(tag)
      }
    },

    addTagReceiver (newTag) {
      let regex = /^[a-zA-Z0-9-,.()@* \b]+$/
      if (!regex.test(newTag)) {
        alert("some of the tag has disallowed character")
      } else {
        const tag = {
          name: newTag,
          code: newTag              }
        this.blockedReceiverNameList.push(tag)
      }
    },

    onChangeRuleCode() {
      this.resetTrsPerRule();
      switch (this.ruleCode) {
        case "RULE0001":
          //show only same sender gap seconds input
          this.seenSameSenderGapSeconds = true;
          this.seenSameSenderReceiverGapSeconds = false;
          this.seenSingleTransactionAmount = false;
          this.seenPrevDayTotalExceedPercentage = false;
          this.seenTotalTransSafetyValue = false;
          this.seenGlobalTransSafetyValue = false;
          this.seenRule0006 = false;
          break;
        case "RULE0002":
          //show only same sender recever gap seconds
          this.seenSameSenderGapSeconds = false;
          this.seenSameSenderReceiverGapSeconds = true;
          this.seenSingleTransactionAmount = false;
          this.seenPrevDayTotalExceedPercentage = false;
          this.seenTotalTransSafetyValue = false;
          this.seenGlobalTransSafetyValue = false;
          this.seenRule0006 = false;
          break;
        case "RULE0003":
          //show max transation limit input
          this.seenSameSenderGapSeconds = false;
          this.seenSameSenderReceiverGapSeconds = false;
          this.seenSingleTransactionAmount = true;
          this.seenPrevDayTotalExceedPercentage = false;
          this.seenTotalTransSafetyValue = false;
          this.seenGlobalTransSafetyValue = false;
          this.seenRule0006 = false;
          break;
        case "RULE0004":
          //show PrevDayTotalExceedPercentage, TotalTransSafetyValue
          this.seenSameSenderGapSeconds         = false;
          this.seenSameSenderReceiverGapSeconds = false;
          this.seenSingleTransactionAmount      = false;
          this.seenPrevDayTotalExceedPercentage = true;
          this.seenTotalTransSafetyValue        = true;
          this.seenGlobalTransSafetyValue       = false;
          this.seenRule0006 = false;
          break;
        case "RULE0006":
          //show PrevDayTotalExceedPercentage, TotalTransSafetyValue
          this.seenSameSenderGapSeconds         = false;
          this.seenSameSenderReceiverGapSeconds = false;
          this.seenSingleTransactionAmount      = false;
          this.seenPrevDayTotalExceedPercentage = false;
          this.seenTotalTransSafetyValue        = false;
          this.seenGlobalTransSafetyValue       = false;
          this.seenRule0006                     = true;
        break;
        default:
          //show RULE0005 GlobalTransSafetyValue
          this.seenSameSenderGapSeconds         = false;
          this.seenSameSenderReceiverGapSeconds = false;
          this.seenSingleTransactionAmount      = false;
          this.seenPrevDayTotalExceedPercentage = false;
          this.seenTotalTransSafetyValue        = false;
          this.seenGlobalTransSafetyValue       = true;
          this.seenRule0006 = false;
      }
    },

    resetTrsPerRule() {
      this.prevDayTotalExceedPercentage = 0;
      this.sameSenderGapSeconds = 0;
      this.sameSenderReceiverGapSeconds = 0;
      this.singleTransactionAmount = 0;
      this.totalTransSafetyValue = 0;
      this.globalTransSafetyValue=0;
    },

    ActionCancel() {
      var trTypeCode = this.transactionTypeCode;
      this.$router.push({ name: 'typerulesmap', params: { trTypeCode: trTypeCode } })
    },

    calcSameSenderGapSeconds() {
      let ssgsTime = this.timeToSeconds(this.ssgsDay, this.ssgsHour, this.ssgsMin, this.ssgsScd);
      this.sameSenderGapSeconds = ssgsTime;
    },

    calcSameSenderReceiverGapSeconds() {
      var ssrgsTime = this.timeToSeconds(this.ssrgsDay, this.ssrgsHour, this.ssrgsMin, this.ssrgsScd);
      this.sameSenderReceiverGapSeconds = ssrgsTime;
    },

    ActionUpdate() {
      let params = {}; var validate = [];

      if (this.ruleCode === "RULE0001") {
        this.calcSameSenderGapSeconds();
      }

      if (this.ruleCode === "RULE0002") {
        this.calcSameSenderReceiverGapSeconds();
      }

      params['id'] = this.id;
      params['prevDayTotalExceedPercentage'] = this.prevDayTotalExceedPercentage;
      params['sameSenderGapSeconds'] = this.sameSenderGapSeconds;
      params['sameSenderReceiverGapSeconds'] = this.sameSenderReceiverGapSeconds;
      params['singleTransactionAmount'] = this.singleTransactionAmount;
      params['status'] = this.status;
      params['totalTransSafetyValue'] = this.totalTransSafetyValue;
      params['globalTransSafetyValue'] = this.globalTransSafetyValue;

      let blockedacc      = [];
      let blockedbank     = [];
      let blockeduen      = [];
      let blockednric     = [];
      let blockedemail    = [];
      let blockedmobile   = [];
      let blockedreceiver = [];

      if(this.blockedAccountNumberList.length > 0){
        this.blockedAccountNumberList.forEach((valAcc, keyAcc)=>{
          blockedacc.push(valAcc["code"]);
        });
        params['blockedAccountNumberList'] = blockedacc.join("|");
      }

      if(this.blockedBankList.length > 0){
        this.blockedBankList.forEach((valBank, keyBank)=>{
          blockedbank.push(valBank["code"]);
        });
        params['blockedBankList'] = blockedbank.join("|");
      }

      if(this.blockedUenList.length > 0){
        this.blockedUenList.forEach((valUen, keyUen)=>{
          blockeduen.push(valUen["code"]);
        });
        params['blockedUenList'] = blockeduen.join("|");
      }

      if(this.blockedNricList.length > 0){
        this.blockedNricList.forEach((valNRIC, keyNRIC)=>{
          blockednric.push(valNRIC["code"]);
        });
        params['blockedNricList'] = blockednric.join("|");
      }

      if(this.blockedEmailList.length > 0){
        this.blockedEmailList.forEach((valEmail, keyEmail)=>{
          blockedemail.push(valEmail["code"]);
        });
        params['blockedEmailList'] = blockedemail.join("|");
      }

      if(this.blockedMobileList.length > 0){
        this.blockedMobileList.forEach((valMobile, keyMobile)=>{
          blockedmobile.push(valMobile["code"]);
        });
        params['blockedMobileList'] = blockedmobile.join("|");
      }

      if(this.blockedReceiverNameList.length > 0){
        this.blockedReceiverNameList.forEach((valReceiver, keyReceiver)=>{
          blockedreceiver.push(valReceiver["code"]);
        });
        params['blockedReceiverNameList'] = blockedreceiver.join("|");
      }

      if (!validate.includes(false)) { //console.log(params);
         this.updateTransactionRule(params);
      } else {
        this.msg = 'Data Not Complete, All fields required !';
        this.color = 'warning'; this.seen = true;
      }

      setTimeout(() => this.seen = false, 5000);
    },

    convertToTimeFormat(val) {
      var pretime = val.length == 1 ? "00:00:0" : "00:00:";
      var result = pretime + val;
      return result;
    },

    getTransactionRuleById: function (id) {
      FraudService.getTransactionRuleById(id).then(resp => {
        this.transactionTypeCode = resp.transactionTypeCode;
        this.ruleCode = resp.ruleCode;
        this.status   = resp.status;

        FraudService.getAllRule().then(resp2 => {
          let ruleList  = {};
          for (var key in resp2) {
            var row = resp2[key]; ruleList[row.ruleCode] = row;
          }
          this.ruleDesc = ruleList[resp.ruleCode].description
        }, error => {
          this.spinner = false;
        });

        switch (resp.ruleCode) {
          case "RULE0001":
            this.seenSameSenderGapSeconds = true;
            this.seenSameSenderReceiverGapSeconds = false;
            this.seenSingleTransactionAmount = false;
            this.seenPrevDayTotalExceedPercentage = false;
            this.seenTotalTransSafetyValue = false;
            this.seenGlobalTransSafetyValue = false;

            var ssgsval   = this.countdown(resp.sameSenderGapSeconds);
            this.ssgsDay  = ssgsval[0];
            this.ssgsHour = ssgsval[1];
            this.ssgsMin  = ssgsval[2];
            this.ssgsScd  = ssgsval[3];
            this.sameSenderGapSeconds = resp.sameSenderGapSeconds;
            this.seenRule0006 = false;
            break;
          case "RULE0002":
            this.seenSameSenderGapSeconds = false;
            this.seenSameSenderReceiverGapSeconds = true;
            this.sameSenderReceiverGapSeconds = false;
            this.seenSingleTransactionAmount = false;
            this.seenPrevDayTotalExceedPercentage = false;
            this.seenTotalTransSafetyValue = false;
            this.seenGlobalTransSafetyValue = false;

            var ssrgsval   = this.countdown(resp.sameSenderReceiverGapSeconds);
            this.ssrgsDay  = ssrgsval[0];
            this.ssrgsHour = ssrgsval[1];
            this.ssrgsMin  = ssrgsval[2];
            this.ssrgsScd  = ssrgsval[3];
            this.sameSenderReceiverGapSeconds = resp.sameSenderReceiverGapSeconds;
            this.seenRule0006 = false;
            break;
          case "RULE0003":
            this.seenSameSenderGapSeconds = false;
            this.seenSameSenderReceiverGapSeconds = false;
            this.seenSingleTransactionAmount = true;
            this.singleTransactionAmount = resp.singleTransactionAmount.toFixed(2);
            this.seenPrevDayTotalExceedPercentage = false;
            this.seenTotalTransSafetyValue = false;
            this.seenGlobalTransSafetyValue = false;
            this.seenRule0006 = false;
            break;
          case "RULE0004":
            this.seenSameSenderGapSeconds = false;
            this.seenSameSenderReceiverGapSeconds = false;
            this.seenSingleTransactionAmount = false;
            this.seenPrevDayTotalExceedPercentage = true;
            this.seenTotalTransSafetyValue = true;
            this.prevDayTotalExceedPercentage = resp.prevDayTotalExceedPercentage;
            this.totalTransSafetyValue = resp.totalTransSafetyValue.toFixed(2);
            this.seenRule0006 = false;
            break;
          case "RULE0006":
            //show PrevDayTotalExceedPercentage, TotalTransSafetyValue
            this.seenSameSenderGapSeconds         = false;
            this.seenSameSenderReceiverGapSeconds = false;
            this.seenSingleTransactionAmount      = false;
            this.seenPrevDayTotalExceedPercentage = false;
            this.seenTotalTransSafetyValue        = false;
            this.seenGlobalTransSafetyValue       = false;
            this.seenRule0006                     = true;
            var emailLists                        = [];
            var accountLists                      = [];
            var bankLists                         = [];
            var uenLists                          = [];
            var nricLists                         = [];
            var mobileLists                       = [];
            var receiverLists                     = [];
            if (resp.hasOwnProperty('blockedEmailList') && resp.blockedEmailList !== "") {
                let emailBlockedList = resp.blockedEmailList.split("|");
                emailBlockedList.forEach((valEmail, indexEmail) => {
                    emailLists.push({code: valEmail.toString(), name: valEmail.toString()});
                });
            }
            this.blockedEmailList = emailLists;
            if (resp.hasOwnProperty('blockedAccountNumberList') && resp.blockedAccountNumberList !== "") {
                let accountBlockedList = resp.blockedAccountNumberList.split("|");
                    accountBlockedList.forEach((valAccount, indexAccount) => {
                    accountLists.push({code: valAccount.toString(), name: valAccount.toString()});
                });
            }
            this.blockedAccountNumberList = accountLists;
            if (resp.hasOwnProperty('blockedBankList') && resp.blockedBankList !== "") {
                let bankBlockedList = resp.blockedBankList.split("|");
                    bankBlockedList.forEach((valBank, indexBank) => {
                    bankLists.push({code: valBank.toString(), name: valBank.toString()});
                });
            }
            this.blockedBankList = bankLists;
            if (resp.hasOwnProperty('blockedUenList') && resp.blockedUenList !== "") {
                let uenBlockedList = resp.blockedUenList.split("|");
                    uenBlockedList.forEach((valUen, indexUen) => {
                    uenLists.push({code: valUen.toString(), name: valUen.toString()});
                });
            }
            this.blockedUenList = uenLists;
            if (resp.hasOwnProperty('blockedNricList') && resp.blockedNricList !== "") {
                let nricBlockedList = resp.blockedNricList.split("|");
                    nricBlockedList.forEach((valNric, indexNric) => {
                    nricLists.push({code: valNric.toString(), name: valNric.toString()});
                });
            }
            this.blockedNricList = nricLists;
            if (resp.hasOwnProperty('blockedMobileList') && resp.blockedMobileList !== "") {
                let mobileBlockedList = resp.blockedMobileList.split("|");
                    mobileBlockedList.forEach((valMobile, indexMobile) => {
                    mobileLists.push({code: valMobile.toString(), name: valMobile.toString()});
                });
            }
            this.blockedMobileList = mobileLists;
            if (resp.hasOwnProperty('blockedReceiverNameList') && resp.blockedReceiverNameList !== "") {
                let receiverBlockedList = resp.blockedReceiverNameList.split("|");
                    receiverBlockedList.forEach((valReceiver, indexReceiver) => {
                    receiverLists.push({code: valReceiver.toString(), name: valReceiver.toString()});
                });
            }
            this.blockedReceiverNameList = receiverLists;
          break;
          default:
            this.seenSameSenderGapSeconds = false;
            this.seenSameSenderReceiverGapSeconds = false;
            this.seenSingleTransactionAmount = false;
            this.seenPrevDayTotalExceedPercentage = false;
            this.seenTotalTransSafetyValue = false;
            this.seenGlobalTransSafetyValue = true;
            this.globalTransSafetyValue = resp.globalTransSafetyValue.toFixed(2);
        }
      }, error => {
        this.spinner = false;
      });
    },

    updateTransactionRule: function (params) {
      FraudService.updateTransactionRule(params).then(resp => {
        if (resp.message == 'Success') {
          this.msg    = 'Transaction Rule Successfully Updated';
          this.color  = 'success';
          this.seenSsgsTime  = false;
          this.seenSsrgsTime = false;
          this.cbSsgsDay     = false;
          this.cbSsrgsDay    = false;
          this.getTransactionRuleById(params.id);
        } else {
          this.msg = !resp.data.message ? "Transaction Rule Failed Updated!" : resp.data.message;
          this.color = 'warning';
        }
        this.seen = true;
        setTimeout(() => this.seen = false, 5000);
      }, error => {
        console.log('error');
      });
    },

    getAllRule: function () {
      FraudService.getAllRule().then(resp => {
        var afSrcRule = resp; var afRuleList = [];
        let ruleList  = {};
        for (var key in afSrcRule) {
          var row = afSrcRule[key];
          ruleList[row.ruleCode] = row;
          var name = row.ruleCode + " - " + row.name;
          afRuleList.push({ value: row.ruleCode, text: name });
        }
        this.rules = ruleList;
        this.optRuleCode = afRuleList;
      }, error => {
        this.spinner = false;
      });
    },

  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>